<template>

        <div>

            <div class="h-full w-full rounded-lg flex flex-col overflow-hidden relative bg-filters relative" @click="goComs()">

                <loader :loading="loadData" />

                <div class="pt-2 mb-1 flex flex-row justify-between items-center px-2">
                    <span class="font-semibold text-xs" style="color: #13F2E3">Comms Dashboard</span>
                    <span v-if="dataOut" class="text-3xs text-warning"> {{ dataOut.date | moment('DD / MM / YY') }} </span>
                </div>

                <div v-if="dataOut" class="flex-1 min-h-0 cursor-pointer flex flex-col p-1">

                    <img class="flex-1 min-h-0 flex rounded-lg object-cover bg-center shadow-xl" :src="imageRoute + dataOut.image"/>

                    <div class="h-auto flex flex-row justify-between items-center mt-2">
                        <span class="text-left font-semibold text-white text-xxs">The Essential</span>
                    </div>

                    <div class="flex-1 min-h-0 mt-1 items-center flex flex-col">

                        <div class="flex-1 min-h-0 w-full pr-2">
                            <p :class="(path === 'home') ? 'truncate-2': null" style="font-size: 0.5rem" class="mb-1 text-font-white group-hover:text-font-light
                            text-justify font-medium truncate-2" v-html="dataOut.title"></p>
                        </div>

                        <div class="pl-2 w-full flex flex-col justify-center items-end mr-2 ">
                            <span class="font-semibold text-xs " style="color: #13F2E3; font-size: 0.6rem" >more...</span>
                        </div>

                    </div>

                </div>

                <div v-else class="flex-1 min-h-0 flex flex-col justify-center items-center">
                    <span class="text-xxs">No data</span>
                </div>

            </div>

        </div>

</template>

<script>

const ChartDoubleGauge = () => import('@/charts/ChartDoubleGauge.vue');
import { state } from '@/store';

export default {
    name: "PersonalDashboardHomeMobile",
    components: {
        ChartDoubleGauge
    },
    data() {
        return {
            loadData: false,
            sales: {},
            dataOut: false
        }
    },
    methods: {
        load() {

            this.loadData = true
            
            this.axios.get(process.env.VUE_APP_BACKEY_CMS_URL + 'list?resource=Outstanding&resources=Essential', {params:this.params})
            .then(response => {
                this.loadData = false
                this.dataOut = response.data[0]
            })

        },
        model(key){ 
            return this.$typeModel(key) 
        },
        goComs(){
            this.$router.push({ name: 'HomePersonalDashboard' })
        }
    },
    computed: {
        path() {
            return this.$route.path.split('/')[2] 
        },
        imageRoute() { 
            return this.$imageRoute 
        },
        routes() { 
            return getNestedRoutes('desktop.PersonalDashboard') 
        },
        params() {
            let params = {
                ...state.globalParams,
                roleTypeId:state.globalParams.roleTypeId, 
                gui_role_specific_id:state.globalParams.gui_role_specific_id
            }
            if (this.$route.params.catId) {
                params = {...params, ...{ sectorAmbiciona: this.$route.params.catId }}
            }
            return params
        },
    },
    watch: {
        params() { this.load() }
    },
    mounted() {
        this.load()
    }
}

</script>


<style>

    .bottom-right {
        position: absolute;
        bottom: 8px;
        right: 16px;
    }
</style>